<template>
  <div class="right-sider-container">
    <div class="right-sider-header">
      <div class="path">
        <template v-if="Array.isArray(current.parents) && current.parents.length">
          <span v-for="item in current.parents" @click.stop="handlePathChange(item)" class="path-item" :key="item.id">
            <span class="path-item-name">{{ item.name }}</span>
            <span> /&nbsp;</span>
          </span>
        </template>
        {{ current.name }}
      </div>
      <div class="action">
        <el-tooltip effect="dark" content="返回">
          <el-button @click.stop="hanleBack" type="text">
            <svg class="action-icon" aria-hidden="true" width="18" height="18">
              <use xlink:href="#iconzuojiantou" fill="#409eff" />
            </svg>
          </el-button>
        </el-tooltip>
        <!-- 复制方案有问题待处理 -->
        <!-- <el-tooltip effect="dark" content="复制链接">
          <el-button @click.stop="doCopy" type="text">
            <svg class="action-icon" aria-hidden="true" width="18" height="18">
              <use xlink:href="#iconc-share" fill="#409eff" />
            </svg>
          </el-button>
        </el-tooltip> -->
      </div>
    </div>
    <div class="right-sider-warp">
      <KnowledgeViewer v-if="current && !current.type" :data="current" @change="handleChange"/>
      <RichTextViewer v-if="current && current.type === 'word'" :data="current" :text="wordText" />
      <FolderViewer v-if="current && current.type === 'folder'" :data="current" @change="handleChange" />
      <FileViewer v-if="current && current.type === 'file'" :data="current" />
    </div>
    <input type="text" id="copyInput">
  </div>
</template>
<script>
import { defineComponent, computed } from 'vue'
import RichTextViewer from './RichTextViewer'
import KnowledgeViewer from './KnowledgeViewer.vue'
import FolderViewer from './FolderViewer.vue'
import FileViewer from './FileViewer.vue'
import Router from '@/router'
import { Message, Tooltip } from 'element-ui'
export default defineComponent({
  props: {
    current: {
      type: Object,
      default() {
        return {}
      }
    },
    knowledgeDetails: {
      type: Object,
      default() {
        return {}
      }
    },
  },
  components: {
    RichTextViewer,
    KnowledgeViewer,
    FolderViewer,
    FileViewer,
    'el-tooltip': Tooltip
  },
  setup(props, ctx) {
    const wordText = computed(() => {
      if(props.current && props.current.detail) {
        return props.current.detail.content || ''
      } else {
        return ''
      }
    })
    const hanleEditor = () => {
      Router.push({
        name: 'KnowledgeEditor',
        params: {
          id: props.current.id
        }
      })
    }
    const handleCommand = (command, data = null) => {
      ctx.emit('handleCommand', command, data)
    }
    const handleChange = (res) => {
      ctx.emit('change', res)
    }
    const handlePathChange = (res) => {
      ctx.emit('change', res.id)
    }
    const hanleDown = () => {
      if(props.current.detail.path) {
        let a = document.createElement('a')
        a.href = props.current.detail.path
        a.setAttribute('target', '_blank')
        a.setAttribute('download', props.current.name)
        a.click()
        // 移除a的引用
        setTimeout(() => {
          a = null
        }, 100)
      }
    }
    const hanleBack = () => {
      Router.back()
    }
    const doCopy = () => {
      console.log(`${window.location.protocol}//${window.location.host}${Router.currentRoute.path}?pageId=${props.current.id}`)
      const input = document.getElementById('copyInput')
      input.value = `${window.location.protocol}//${window.location.host}${Router.currentRoute.path}?pageId=${props.current.id}`
      input.select()
      document.execCommand('copy')
      Message.success('复制成功!')
    }
    return {
      wordText,
      hanleEditor,
      handleCommand,
      handleChange,
      handlePathChange,
      hanleDown,
      hanleBack,
      doCopy
    }
  },
})
</script>
<style lang="less" scoped>
  .right-sider-container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .right-sider-header{
      height: 50px;
      display: flex;
      flex-shrink: 0;
      align-items: center;
      justify-content: space-between;
      padding: 0 10px;
      .path{
        display: flex;
        font-weight: 600;
        font-size: 16px;
        .path-item{
          cursor: pointer;
          &:hover{
            .path-item-name{
              color: #409eff;
            }
          }
        }
      }
      .action{
        margin-right: 16px;
        display: flex;
      }
    }
    .right-sider-warp{
      flex-grow: 1;
      height: 10px;
      margin-top: 10px;
    }
    #copyInput{
      position: absolute;
      visibility: hidden;
    }
  }
</style>