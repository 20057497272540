<template>
  <div class="left-sider-container">
    <header class="left-sider-header">
      <div class="title">
        <svg width="20" height="20">
          <use xlink:href="#iconzhishiku1" />
        </svg>
        &nbsp;
        <p class="title-text">
          {{ knowledgeDetails.name }}
        </p>
      </div>
      <div class="action" v-show="knowledgeDetails.is_edit" @click.stop="">
        <div class="left-action">
          <div class="action-item">
            <svg width="16" height="16">
              <use xlink:href="#iconzirenwu" />
            </svg>
            &nbsp;目录
          </div>
        </div>
        <div class="right-action">
          <div class="action-item" @click.stop="searchShow = !searchShow">
            <svg width="16" height="16">
              <use xlink:href="#iconsousuo" />
            </svg>
          </div>
        </div>
        <div class="search" v-show="searchShow">
          <div class="search-input">
            <el-input v-model="searchVal" placeholder="搜索..." />
          </div>
          <div class="search-close" @click.stop="searchShow = !searchShow; searchVal = ''">
            <svg width="16" height="16">
              <use xlink:href="#iconc-close" />
            </svg>
          </div>
        </div>
      </div>
    </header>
    <section class="left-sider-warp">
      <el-tree
        :ref="(el) => { treeRef = el }"
        v-if="Array.isArray(treeData) && treeData.length"
        :data="treeData"
        default-expand-all
        :expand-on-click-node="false"
        node-key="id"
        :filter-node-method="filterNode"
        @node-click="handleNodeClick"
      >
        <div class="custom-tree-node" slot-scope="{ data }">
          <div class="node-label">
            <!-- <div class="icon">
              <svg v-if="data.type === 'word' || data.type === 'folder'" class="action-icon" aria-hidden="true" width="20" height="20">
                <use v-if="data.type === 'word'" xlink:href="#iconwenben1" />
                <use v-else-if="data.type === 'folder'" xlink:href="#iconwenjianjia1" />
              </svg>
              <svg v-else class="action-icon" aria-hidden="true" width="20" height="20">
                <use :xlink:href="getFileSvgIcon(data)" />
              </svg>
            </div> -->
            <div class="label">
              {{ data.name }}
            </div>
          </div>
        </div>
      </el-tree>
      <el-empty v-else description="暂无内容"></el-empty>
    </section>
  </div>
</template>
<script>
import { defineComponent, ref, watch, onMounted } from 'vue'
import { Dropdown, DropdownMenu, DropdownItem, Empty, Tree } from 'element-ui'
export default defineComponent({
  components: {
    'el-dropdown': Dropdown,
    'el-dropdown-menu': DropdownMenu,
    'el-dropdown-item': DropdownItem,
    'el-empty': Empty,
    'el-tree': Tree
  },
  props: {
    knowledgeDetails: {
      type: Object,
      default() {
        return {}
      }
    },
    treeData: {
      type: Array,
      default() {
        return []
      }
    },
    value: {
      type: [String, Number],
      default: ''
    }
  },
  setup(props, ctx) {
    const searchShow = ref(false)
    const searchVal = ref('')
    const treeRef = ref(null)
    const handleNodeClick = (data) => {
      // if(data.type !== 'folder') {
      //   treeRef.value?.setCurrentKey(data.id)
      //   ctx.emit('input', data.id)
      //   ctx.emit('change', data.id)
      // }
      if(data.type) {
        treeRef.value?.setCurrentKey(data.id)
        ctx.emit('input', data.id)
        ctx.emit('change', data.id)
      }
    }
    const fileTypeHash = {
      'bmp': '#iconzhaopian1x',
      'jpg': '#iconzhaopian1x',
      'png': '#iconzhaopian1x',
      'gif': '#iconzhaopian1x',
      'jpeg': '#iconzhaopian1x',
      'cdr': '#iconzhaopian1x',
      'psd': '#iconzhaopian1x',
      'doc': '#iconword1x',
      'docx': '#iconword1x',
      'pdf': '#iconpdf1x',
      'ppt': '#iconppt1x',
      'pptx': '#iconppt1x',
      'xlsx': '#iconexcle1x',
      'xls': '#iconexcle1x',
      'txt': '#icontxt1x',
      'mp3': '#iconyinle',
      'wav': '#iconyinle',
      'mp4': '#iconzhihuigongdi',
      'webm': '#iconzhihuigongdi',
      'ogg': '#iconzhihuigongdi',
    }
    const getFileSvgIcon = (data) => {
      if(!data.detail || !Array.isArray(data.detail) || !data.detail.length || !data.detail[0].path) return
      const arr = data.detail[0].path.split('.')
      const pre = arr[arr.length - 1]
      return fileTypeHash[pre.toLocaleLowerCase()] || '#iconother1x'
    }
    let timer = null
    const handleSearch = (val) => {
      if(treeRef.value) {
        if(timer) {
          clearTimeout(timer)
        }
        timer = setTimeout(() => {
          treeRef.value.filter(val)
          timer = null
        }, 1000)
      }
    }
    const filterNode = (value, data) => {
        if (!value) return true;
        return data.name.indexOf(value) !== -1;
      }
    watch(() => props.value, (n) => {
      if(n && treeRef.value) {
        treeRef.value?.setCurrentKey(n)
      }
    })
    watch(() => searchVal.value, (n) => {
      handleSearch(n)
    })
    onMounted(() => {
      if(props.value && treeRef.value) {
        treeRef.value?.setCurrentKey(props.value)
      }
    })
    return {
      searchShow,
      searchVal,
      handleNodeClick,
      getFileSvgIcon,
      treeRef,
      filterNode
    }
  },
})
</script>
<style lang="less" scoped>
@activeColor: #409eff;
  .left-sider-container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .left-sider-header{
      flex-shrink: 0;
      .title{
        display: flex;
        align-items: center;
        width: 100%;
        padding: 16px 16px;
        box-sizing: border-box;
        .title-text{
          flex-grow: 1;
          width: 10px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 18px;
          font-weight: 600;
          text-align: left;
          cursor: pointer;
        }
      }
      .action{
        position: relative;
        display: flex;
        height: 30px;
        align-items: center;
        justify-content: space-between;
        padding: 10px 16px;
        border-top: 1px solid #f1f1f1;
        .left-action{
          flex-grow: 0;
          display: flex;
        }
        .right-action{
          flex-grow: 0;
          display: flex;
        }
        .action-item{
          display: flex;
          align-items: center;
          padding: 5px;
          border-radius: 5px;
          &:hover{
            background: #f5f5f5;
          }
          & + .action-item{
            margin-left: 10px;
          }
        }
        .search{
          display: flex;
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          align-items: center;
          padding: 5px;
          box-sizing: border-box;
          background: #fff;
          .search-input{
            flex-grow: 1;
          }
          .search-close{
            margin: 0 5px;
            padding: 5px;
            box-sizing: border-box;
            border-radius: 50%;
            &:hover{
              background: #f1f1f1;
            }
          }
        }
      }
    }
    .left-sider-warp{
      flex-grow: 1;
      height: 10px;
      overflow-y: auto;
      :deep(.el-tree-node__expand-icon) {
        font-size: 18px;
      }
      :deep(.el-tree-node__content) {
        width: 100%;
        height: 40px;
        box-sizing: border-box;
      }
      :deep(.is-current) {
        & > .el-tree-node__content > .custom-tree-node > .node-label{
          color: @activeColor;
        }
      }
      .custom-tree-node{
        flex-grow: 1;
        width: 10px;
        height: 100%;
        display: flex;
        .node-label{
          height: 100%;
          flex-grow: 1;
          width: 10px;
          display: flex;
          align-items: center;
          .icon{
            flex-shrink: 0;
            margin-right: 6px;
          }
          .label{
            padding: 3px 0;
            flex-grow: 1;
            width: 10px;
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }
  }
</style>