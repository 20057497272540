<template>
  <div v-loading="konwledgeLoading" class="konwledge-details-container normalpage-container">
    <template v-if="knowledgeDetails">
      <aside class="left-sider" :class="{ 'is-active': expend }" :style="{ width: siderWidth }" v-loading="treeDataLoading">
        <div class="expand-switch" :class="{ 'is-open': expend }" @click="expend = !expend">
          <svg class="expand-icon">
            <use xlink:href="#iconshuangjiantou_shang" />
          </svg>
        </div>
        <PageTree
          v-if="!treeDataLoading"
          :treeData="treeData" 
          v-model="activeID"
          :knowledgeDetails="knowledgeDetails"
          @change="handleChange"
        />
      </aside>
      <section class="right-sider" v-loading="currentDataLoading">
        <RightSider 
          :current="currentData"
          @change="handleChange"
          :knowledgeDetails="knowledgeDetails"
        />
      </section>
    </template>
    <el-empty style="width: 100%" v-else description="发生了一些错误，您要的页面不见了"></el-empty>
  </div>
</template>
<script>
import PageTree from './components/PageTree.vue'
import RightSider from './components/RightSider.vue'
import { defineComponent, ref, onMounted, computed } from 'vue'
import Router from '@/router'
import { knowledgePageWebSite, officialKnowledgeDetail, officialKileDetails } from '@/saas-apis/konwledgeBase.js'
import { Message, Empty } from 'element-ui'
export default defineComponent({
  components: {
    PageTree,
    RightSider,
    'el-empty': Empty,
  },
  setup() {
    const expend = ref(true)
    const siderWidth = computed(() => {
      return expend.value ? '' : '0px'
    })
    const knowledgeDetails = ref(null)
    const konwledgeLoading = ref(false)
    const getknowledgeDetails = () => {
      const reqData = {
        repository_id: Router.currentRoute.query.id
      }
      konwledgeLoading.value = true
      officialKnowledgeDetail(reqData).then(res => {
        if(res.data.code === 200) {
          knowledgeDetails.value = res.data.data
          currentData.value = res.data.data
        }
      }).catch((res) => {
        Message.error(res.data.msg)
      }).finally(() => {
        konwledgeLoading.value = false
      })
    }
    getknowledgeDetails()
    const treeData = ref([])
    const treeDataLoading = ref(false)
    const getTreeData = () => {
      const reqData = {
        repository_id: Router.currentRoute.query.id,
      }
      treeDataLoading.value = true
      knowledgePageWebSite.get(reqData).then(res => {
        if(res.data.code === 200) {
          treeData.value = res.data.data
          if(!activeID.value) {
            if(res.data.data.length) {
              handleChange(res.data.data[0].id)
            }
          }
        }
      }).catch((res) => {
        Message.error(res.data.msg)
      }).finally(() => {
        treeDataLoading.value = false
      })
    }
    getTreeData()
    const currentData = ref(null)
    const currentDataLoading = ref(false)
    const activeID = ref('')
    // activeID change
    const handleChange = (id) => {
      activeID.value = id
      if(!id) {
        currentData.value = knowledgeDetails.value
        return
      }
      const reqData = {
        repository_id: Router.currentRoute.query.id,
        page_id: id,
      }
      currentDataLoading.value = true
      officialKileDetails(reqData).then(res => {
        if(res.data.code === 200) {
          currentData.value = res.data.data
        }
      }).catch((res) => {
        Message.error(res.data.msg)
      }).finally(() => {
        currentDataLoading.value = false
      })
    }
    onMounted(() => {
      if(Router.currentRoute.query.pageId) {
        handleChange(Router.currentRoute.query.pageId)
      }
    })
    return {
      expend,
      siderWidth,
      treeData,
      handleChange,
      currentData,
      konwledgeLoading,
      treeDataLoading,
      knowledgeDetails,
      currentDataLoading,
      activeID
    }
  },
})
</script>
<style lang="less" scoped>
  .konwledge-details-container{
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    text-align: left;
    background: #fff;
    .left-sider{
      width: 300px;
      flex-shrink: 0;
      position: relative;
      transition: width .5s;
      @media all and (max-width: 1300px) {
        & {
          position: absolute;
          left: 0;
          top: 0;
          z-index: 100;
          height: 100%;
          background: #fff;
          box-shadow: 0px 2px 4px 2px #ddd;
        } 
      }
      .expand-switch{
        position: absolute;
        top: 40px;
        right: 0;
        z-index: 1;
        width: 30px;
        height: 30px;
        transform: translateX(100%);
        transition: all .5s;
        box-shadow: 2px 2px 5px 3px #ccc;
        border-radius: 0 50% 50% 0;
        background: #fff;
        .expand-icon{
          width: 100%;
          height: 100%;
          transform: rotate(90deg);
          transition: all .5s;
        }
      }
      .is-open{
        width: 25px;
        height: 25px;
        transform: translateX(50%);
        border-radius: 50%;
        visibility: hidden;
        .expand-icon{
          transform: rotate(-90deg);
          visibility: hidden;
        }
      }
      &:hover{
        .is-open{
          transition: all .5s;
          visibility: visible;
          .expand-icon{
            visibility: visible;
          }
        }
      }
    }
    .is-active{
      border-right: 1px solid #f1f1f1;
    }
    .right-sider{
      flex-grow: 1;
      width: 10px;
      padding: 0 10px;
      box-sizing: border-box;
    }
  }
</style>
